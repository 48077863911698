import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { I18NService } from '../../../api/g/controllers/I18N';

export class HttpTranslateLoader extends TranslateLoader {
    constructor(private i18nService: I18NService) {
        super();
    }

    getTranslation(): Observable<any> {
        return this.i18nService.getTranslations();
    }
}
