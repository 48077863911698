import { Router } from '@angular/router';

/**
 * Hiba, amit akkor dobunk, ha a jelenlegi feldolgozás megszakításával akarunk navigációt kezdeményezni az alkalmazásban
 * (l. még GlobalErrorHandler).
 * */
export class ShortcutNavigationError {
    private _navigationAction: (router: Router) => void;

    constructor(navigationAction: (router: Router) => void) {
        this._navigationAction = navigationAction;
    }

    public get navigationAction() {
        return this._navigationAction;
    }
}
