import { EventEmitter, Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { delay, take } from 'rxjs/operators';
import { MessageModalComponent } from '../../shared/components/message-modal/message-modal.component';

export interface IModalState {
    show: boolean;
    text?: string;
    showProgress: boolean;
    progress: number;
}

export interface ILoaderState {
    show: boolean;
    text?: string;
}


export interface IModalMessage {
    type: 'error' | 'warning' | 'info';
    title?: string;
    message: string;
    info?: string;
    buttons?: ('ok' | 'cancel' | 'yes' | 'no' | 'close')[];
    onClose?: (result: string) => void;
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    public suppressLoader = false;

    public modalState$: EventEmitter<IModalState> = new EventEmitter();
    public loaderState$: EventEmitter<ILoaderState> = new EventEmitter();

    constructor(
        private bsModalService: BsModalService) { }

    private modalWaitCounter = 0;

    public showModalWait(text: string, progress: number | null = null) {
        this.modalState$.emit({
            show: true,
            text: text,
            showProgress: progress !== null,
            progress: progress !== null ? <number>progress : 0
        });
    }

    public hideModalWait() {
        this.modalState$.emit({
            show: false,
            text: '',
            showProgress: false,
            progress: 0
        });
    }

    public showLoader(text: string) {
        this.loaderState$.emit({
            show: true,
            text: text
        });
    }
    public hideLoader() {
        this.loaderState$.emit({
            show: false,
            text: ''
        });
    }

    public showModalMessage(params: IModalMessage) {
        return new Promise<string>(resolve => {
            const onClose = params.onClose ?
                (result: string) => {
                    if (params.onClose !== undefined) {
                        params.onClose(result);
                    }
                    resolve(result);
                } :
                resolve;

            let buttons = params.buttons;
            if (!params.buttons || !params.buttons.length) {
                buttons = ['close'];
            }

            this.bsModalService.show(MessageModalComponent, {
                initialState: {
                    message: { ...params, buttons, onClose }
                }
            });
        });
    }

    public hideAll() {
        const modalCount = this.bsModalService.getModalsCount();
        if (modalCount === 0) {
            return Promise.resolve();
        }

        return new Promise((resolve: any) => { 
            this.bsModalService.onHidden
                .pipe(take(modalCount), delay(0))
                .subscribe({
                    next: (value: any) => {},
                    error: (error: any) => {},
                    complete: resolve
                });
        
            // https://github.com/valor-software/ngx-bootstrap/issues/2618#issuecomment-398229325
            for (let i = 1; i <= modalCount; i++) {
                this.bsModalService.hide(i);
            }
        });
    }
}
