import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalPermissions } from '../../../api/enums/global-permissions';
import { ProjectPermissions } from '../../../api/enums/project-permissions';
import { AuthService } from '../../../api/g/controllers/Auth';
import { ConfigService } from '../../../api/g/controllers/Config';
import { GetUserInfoResult, LoginResponseModel, LoginResponseUserDataModel, ProjectData } from '../../../api/g/model';
import { NotificationService } from './notification.service';
import { ProjectService } from '../../../api/g/controllers/Project';
import { ReflexRoles } from '../../../api/enums/reflex-roles';

@Injectable({
    providedIn: 'root',
})
export class SessionProvider {
    authorized$: EventEmitter<boolean> = new EventEmitter();
    projectChanged$: EventEmitter<number | null> = new EventEmitter();
    configLoaded$: EventEmitter<null> = new EventEmitter();

    sideNavSlimmed$: EventEmitter<boolean> = new EventEmitter();
    sideNavShown$: EventEmitter<boolean> = new EventEmitter();

    /* a konfiguráció kulcsai */
    private readonly APPVERSION_KEY = 'AppVersion';
    private readonly MAXPAGESIZE_KEY = 'MaxPageSize';
    private readonly APPLICATION_ROLES_KEY = 'ApplicationRoles';

    private readonly AVAILABLE_LANGUAGES_KEY = 'AvailableLanguages';
    private readonly DEFAULT_LANGUAGE_KEY = 'DefaultLanguage';
    private readonly CURRENT_LANGUAGE_KEY = 'CurrentLanguage';

    private readonly PINLENGTH_KEY = 'PINLength';

    /* a perzisztens (local storage-ben tárolt) értékek kulcsai */
    private readonly JWT_TOKEN_KEY = 'jwttoken';

    private readonly SIDENAV_SHOWN_KEY = 'SideNavShown';
    private readonly SIDENAV_SLIMMED_KEY = 'SideNavSlimmed';

    private readonly SELECTED_ROWS_KEY_PREFIX = 'SelectedRows.';
    private readonly FILTERS_KEY_PREFIX = 'Filters.';

    private readonly USERDATA_KEY = 'user';

    /* tranziens (az alkalmazás élettartama alatt tárolt) értékek */
    private appVersion!: string;
    private maxPageSize?: number;
    private defaultLang!: string;
    private pinLength = 5;
    private readonly DEFAULT_PAGE_SIZE = 100;

    private userId?: number;
    private globalPermissions!: GlobalPermissions;
    private projectPermissions!: ProjectPermissions;
    private currentProjectId: number | null = null;
    private currentProject: ProjectData | null = null;

    private reflexRole: ReflexRoles | null = null;

    constructor(
        private authService: AuthService,
        private configService: ConfigService,
        private router: Router,
        private notificationService: NotificationService,
        private translator: TranslateService,
        private projectService: ProjectService) {
    }

    async reloadConfig() {
        const config = (await this.configService.config().toPromise()) as StringKeyedDictionaryLike<string>;

        // TODO: config értékek frissítése hogyan/mikor?
        this.appVersion = config[this.APPVERSION_KEY];
        this.maxPageSize = +config[this.MAXPAGESIZE_KEY];
        this.reflexRole = +config[this.APPLICATION_ROLES_KEY];

        if (isNaN(this.maxPageSize)) {
            this.maxPageSize = void 0;
        }

        // konfiguráljuk a TranslateService-t
        const langs = (config[this.AVAILABLE_LANGUAGES_KEY] || '').split(',').map(l => l.trim());
        this.translator.langs = [];
        this.translator.addLangs(langs);

        this.defaultLang = config[this.DEFAULT_LANGUAGE_KEY] || 'hu-HU';

        if (config[this.PINLENGTH_KEY] !== undefined) {
            this.pinLength = +config[this.PINLENGTH_KEY];
        }

        // beállítjuk a használandó nyelvet
        let currentLang = config[this.CURRENT_LANGUAGE_KEY] ?? this.getCurrentLanguage();
        if (!currentLang) {
            currentLang = this.translator.getBrowserCultureLang();
        }

        if (langs.findIndex(lang => lang === currentLang) < 0) {
            currentLang = this.defaultLang;
        }

        this.setCurrentLanguage(currentLang);
        await this.translator.use(currentLang).toPromise();

        this.configLoaded$.emit();
    }

    async check(): Promise<boolean> {
        let result: GetUserInfoResult;
        try {
            result = await this.authService.check().toPromise().then();
        } catch (error) {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                return false;
            }

            throw error;
        }

        this.userId = result.userId;
        this.globalPermissions = result.globalPermissions || 0;
        this.projectPermissions = result.projectPermissions || 0;

        this.setToken(result.token);

        const p = result.currentProjectId === undefined ? null : result.currentProjectId;
        if (this.currentProjectId !== p) {
            this.currentProjectId = p;
            this.handleProjectChanged(this.currentProjectId);
        }

        return true;
    }

    async login(username: string, password: string, redirectUrl?: string): Promise<boolean | undefined> {
        let result: LoginResponseModel;
        try {
            result = await this.authService.login({
                body: {
                    username: username,
                    password: password,
                }
            }).toPromise().then();
        } catch (error) {
            if (error instanceof HttpErrorResponse && error.status === 403) {
                return;
            }

            throw error;
        }

        this.setUserData(result.user);

        this.setToken(result.token);
        this.authorized$.emit(true);

        return await this.navigateSafe(router => router.navigateByUrl(redirectUrl || '/'));
    }

    logout(navigationExtras?: NavigationExtras): Promise<boolean> {
        localStorage.removeItem(this.JWT_TOKEN_KEY);

        this.userId = void 0;
        this.globalPermissions = 0;
        this.projectPermissions = 0;
        this.currentProjectId = null;

        this.setUserData(undefined);

        this.authorized$.emit(false);

        return this.navigateToHomePage(navigationExtras);
    }

    async navigateSafe(navigationAction: (router: Router) => Promise<boolean>) {
        await this.notificationService.hideAll();
        return await navigationAction(this.router);
    }

    navigateToHomePage(extras?: NavigationExtras) {
        return this.getToken() ?
            this.navigateSafe(router => router.navigateByUrl('/', extras)) :
            this.navigateSafe(router => router.navigate(['/login'], extras));
    }

    setToken(token?: string): void {
        if (typeof token !== 'undefined') {
            localStorage.setItem(this.JWT_TOKEN_KEY, token);
        } else {
            localStorage.removeItem(this.JWT_TOKEN_KEY);
        }
    }
    getToken(): string | undefined {
        const value = localStorage.getItem(this.JWT_TOKEN_KEY);
        return value !== null ? value : void 0;
    }

    setCurrentLanguage(language?: string): void {
        if (typeof language !== 'undefined') {
            localStorage.setItem(this.CURRENT_LANGUAGE_KEY, language);
        } else {
            localStorage.removeItem(this.CURRENT_LANGUAGE_KEY);
        }
    }
    getCurrentLanguage(): string | undefined {
        const value = localStorage.getItem(this.CURRENT_LANGUAGE_KEY);
        return value !== null ? value : void 0;
    }

    getUserId(): number | undefined {
        return this.userId;
    }

    getGlobalPermissions(): GlobalPermissions {
        return this.globalPermissions;
    }

    getProjectPermissions(): ProjectPermissions {
        return this.projectPermissions;
    }

    projectPermissionGranted(permission: number) {
        return this.projectPermissions.hasAllFlags(permission);
    }
    globalPermissionGranted(permission: number) {
        return this.globalPermissions.hasAllFlags(permission);
    }

    getCurrentProjectId(): number | null {
        return this.currentProjectId;
    }

    getCurrentProject(): ProjectData | null {
        return this.currentProject;
    }

    getAppVersion(): string {
        return this.appVersion;
    }

    getMaxPageSize(): number | undefined {
        return this.maxPageSize;
    }

    getReflexRole(): ReflexRoles | null {
        return this.reflexRole;
    }

    getUserPINLength(): number {
        return this.pinLength;
    }

    get sideNavShown() {
        return localStorage.getItem(this.SIDENAV_SHOWN_KEY) === 'true';
    }

    set sideNavShown(value: boolean) {
        localStorage.setItem(this.SIDENAV_SHOWN_KEY, JSON.stringify(value));
    }

    toggleSideNavShown() {
        this.sideNavShown = !this.sideNavShown;
        this.sideNavShown$.emit(this.sideNavShown);
    }

    get sideNavSlimmed() {
        return localStorage.getItem(this.SIDENAV_SLIMMED_KEY) === 'true';
    }

    set sideNavSlimmed(value: boolean) {
        localStorage.setItem(this.SIDENAV_SLIMMED_KEY, JSON.stringify(value));
    }

    toggleSideNavSlimmed() {
        this.sideNavSlimmed = !this.sideNavSlimmed;
        this.sideNavSlimmed$.emit(this.sideNavSlimmed);
    }

    saveRowSelection(tableName: string, values: any[]) {
        localStorage.setItem(this.SELECTED_ROWS_KEY_PREFIX + tableName, JSON.stringify(values));
    }

    getRowSelection(tableName: string): any[] {
        const values = localStorage.getItem(this.SELECTED_ROWS_KEY_PREFIX + tableName);
        return values !== null ? JSON.parse(values) : [];
    }
    clearRowSelection(tableName: string) {
        localStorage.removeItem(this.SELECTED_ROWS_KEY_PREFIX + tableName);
    }
    clearAllRowSelection() {
        this.clearAllKeysWithPrefix(this.SELECTED_ROWS_KEY_PREFIX);
    }
    saveFilterStates(tableName: string, values: any) {
        localStorage.setItem(this.FILTERS_KEY_PREFIX + tableName, JSON.stringify(values));
    }
    getFilterStates<T>(tableName: string): T | null {
        const values = localStorage.getItem(this.FILTERS_KEY_PREFIX + tableName);
        return values !== null ? <T>JSON.parse(values) : null;
    }
    clearAllFilterStates() {
        this.clearAllKeysWithPrefix(this.FILTERS_KEY_PREFIX);
    }
    clearAllKeysWithPrefix(prefix: string) {
        for (var key in localStorage) {
            if (key.indexOf(prefix) == 0) {
                localStorage.removeItem(key);
            }
        }
    }
    async leaveProject(projectId: number) {
        await this.projectService.leave({
            projectId: projectId
        }).toPromise();
        this.clearAllRowSelection();
        this.clearAllFilterStates();
        await this.check();
    }
    setUserData(user: LoginResponseUserDataModel | undefined) {
        if (user !== undefined) {
            localStorage.setItem(this.USERDATA_KEY, JSON.stringify(user));
        } else {
            localStorage.removeItem(this.USERDATA_KEY);
        }
    }
    getUserData(): LoginResponseUserDataModel | undefined {
        const user = localStorage.getItem(this.USERDATA_KEY);
        if (user !== null) {
            return JSON.parse(user);
        } else {
            return undefined;
        }
    }

    getDefaultPageSize(): number {
        return this.DEFAULT_PAGE_SIZE;
    }

    private async handleProjectChanged(projectId: number | null) {
        if (projectId !== undefined && projectId !== null) {
            this.currentProject = await this.projectService.getProject({
                projectId: projectId
            }).toPromise().then();
        } else {
            this.currentProject = null;
        }
        this.projectChanged$.emit(projectId);
    }

}
