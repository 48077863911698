/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface GetParams {
  body?: __model.ListCreateTaskQuery;
}

export interface SelectAllParams {
  body?: __model.SelectAllCreateTaskQuery;
}

export interface CreateTaskParams {
  body?: __model.CreateTaskCommand;
}

export interface CreateAutomaticTaskParams {
  body?: __model.CreateAutomaticTaskCommand;
}

@Injectable()
export class CreateTaskService {
  constructor(private http: HttpClient) {}

  /**
   * Adatok listázása feladat létrehozásához
   * http://undefined/swagger/swagger-ui.html#!/CreateTask/Get
   */
  get(params: GetParams): Observable<__model.ListCreateTaskResult> {
    const bodyParams = params.body;

    return this.http.post<__model.ListCreateTaskResult>(`/api/project/CreateTask/Get`, bodyParams || {});
  }

  /**
   * Feladat létrehozás összes sor kiválasztása
   * http://undefined/swagger/swagger-ui.html#!/CreateTask/SelectAll
   */
  selectAll(params: SelectAllParams): Observable<__model.SelectAllCreateTaskResult> {
    const bodyParams = params.body;

    return this.http.post<__model.SelectAllCreateTaskResult>(`/api/project/CreateTask/SelectAll`, bodyParams || {});
  }

  /**
   * Feladat létrehozása
   * http://undefined/swagger/swagger-ui.html#!/CreateTask/Post
   */
  createTask(params: CreateTaskParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/project/CreateTask`, bodyParams || {});
  }

    /**
   * Feladat Automatikus létrehozása
   * http://undefined/swagger/swagger-ui.html#!/CreateTask/Post
   */
    createAutomaticTask(params: CreateAutomaticTaskParams): Observable<void> {
      const bodyParams = params.body;
  
      return this.http.post<void>(`/api/project/CreateAutomaticTask`, bodyParams || {});
    }
}
