import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { DataSyncQueueStatus } from '../g/defs/DataSyncQueueStatus';

@Injectable()
export class DatasyncQueueStatusService {
    eventReceived = new EventEmitter<DataSyncQueueStatus>();
    connectionEstablished = new EventEmitter<Boolean>();

    private connectionIsEstablished = false;
    private _hubConnection: HubConnection | undefined;

    constructor() {
        this.createConnection();
        this.registerOnServerEvents();
        this.startConnection();
    }

    private createConnection() {
        this._hubConnection = new HubConnectionBuilder()
            .withUrl('/DatasyncQueueStatusHub')
            .build();
    }

    private startConnection(): void {
        if (this._hubConnection !== undefined) {
            this._hubConnection
                .start()
                .then(() => {
                    this.connectionIsEstablished = true;
                    this.connectionEstablished.emit(true);
                })
                .catch(err => {
                    console.log('Error while establishing connection, retrying...');
                    setTimeout(() => { this.startConnection(); }, 5000);
                });
        }
    }

    private registerOnServerEvents(): void {
        if (this._hubConnection !== undefined) {
            this._hubConnection.on('EmitEvent', (evt: DataSyncQueueStatus) => {
                this.eventReceived.emit(evt);
            });
        }
    }
}
