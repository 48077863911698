// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info {
  font-size: 0.9em;
  text-align: left;
  margin-top: 10px;
}
.info i {
  font-size: 2.5em;
  color: green;
  float: left;
  padding: 5px;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/message-modal/message-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,gBAAA;AACF;AACE;EACE,gBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AACJ","sourcesContent":[".info {\n  font-size: 0.9em;\n  text-align:left;\n  margin-top:10px;\n\n  i {\n    font-size: 2.5em;\n    color: green;\n    float: left;\n    padding: 5px;\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
