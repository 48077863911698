// a  Reflex.Service.Contract.Infrastructure.BackgroundWork.JobErrorCode C# enum tükörképe, manuálisan kell szinkronban tartani!
export enum JobErrorCode {
    Unknown,
    CannotConnectToCloudServer,
    AccessDeniedByCloudServer,
    InvalidCloudDataFormat,
    ProjectInventoryLeaderNotExists = 1000,
    UnknownImportError = 2000,
    InvalidItemImportFormat = 2001,
    InvalidItemStockFormat = 2002,
    SQLErrorDuringImport = 2003,
}
