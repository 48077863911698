import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Az Angular rosszul kezeli a + jeleket a query stringben, ezért nekünk kell gondoskodni az escape-eléséről.
// https://stackoverflow.com/questions/51942703/character-converting-into-space-in-httpparams-angular-6

@Injectable()
export class QueryStringFixerInterceptor implements HttpInterceptor {
    // https://github.com/angular/angular/issues/11058#issuecomment-449405836
    private static readonly customHttpParameterCodec: HttpParameterCodec = {
        encodeKey: encodeURIComponent,
        encodeValue: encodeURIComponent,
        decodeKey: decodeURIComponent,
        decodeValue: decodeURIComponent
    };

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const keys = req.params.keys();
        if (keys.length) {
            let fixedParams = new HttpParams({
                encoder: QueryStringFixerInterceptor.customHttpParameterCodec
            });

            for (let i = 0; i < keys.length; i++) {
                const params = req.params.getAll(keys[i]);
                if (params !== null) {
                    for (let j = 0, values = params; j < values.length; j++) {
                        fixedParams = fixedParams.append(keys[i], values[j]);
                    }
                }
            }

            req = req.clone({
                params: fixedParams
            });
        }

        return next.handle(req);
    }
}
