import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform<T>(items: T[] | undefined, searchText: string, selector?: (item: T) => string | string[] | undefined,
        caseSensitive: boolean = false): T[] {
        if (!items) {
            return [];
        }

        if (!searchText) {
            return items;
        }

        if (!selector) {
            selector = item => item as any;
        }

        const applyCase = caseSensitive ?
            (value: string) => value :
            (value: string) => value.toLowerCase();

        const test = (text?: string) => text ? applyCase(text).indexOf(searchText) >= 0 : false;

        searchText = applyCase(searchText);

        return items.filter(item => {
            if (selector !== undefined) {
                const text = selector(item);
                return Array.isArray(text) ? text.findIndex(test) >= 0 : test(text);
            }
        });
    }
}
