import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionProvider } from '../../core/providers/session.provider';


@Injectable()
export class TimezoneOffsetInterceptor implements HttpInterceptor {
    constructor(
        private session: SessionProvider) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: { 'X-Timezone-Offset': `${new Date().getTimezoneOffset() * -1}` }
        });

        return next.handle(request);
    }
}
