/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface GetParams {
  SearchTerm?: string;
  OrderColumns?: string[];
  /** format: int32 */
  PageIndex?: number;
  /** format: int32 */
  PageSize?: number;
  /** format: int32 */
  MaxPageSize?: number;
  SkipTotalItemCount?: boolean;
  /** format: int32 */
  ProjectId?: number;
}

export interface PostParams {
  body?: __model.ItemData;
}

export interface EanCodesParams {
  SearchTerm?: string;
  OrderColumns?: string[];
  /** format: int32 */
  PageIndex?: number;
  /** format: int32 */
  PageSize?: number;
  /** format: int32 */
  MaxPageSize?: number;
  SkipTotalItemCount?: boolean;
  /** format: int32 */
  ProjectId?: number;
}

export interface SelectAllParams {
  SearchTerm?: string;
  /** format: int32 */
  ProjectId?: number;
}

export interface DeleteParams {
  body?: __model.DeleteItemsModel;
}

export interface UploadParams {
  /**
   * Az importálás típusától vehet fel két értéket: "items" vagy "stock"
   * format: int32
   */
  fileType: FileTypeUploadParamsEnum;
  /** format: binary */
  file?: string;
}

export type FileTypeUploadParamsEnum =
  1 |
  2;

@Injectable()
export class ItemService {
  constructor(private http: HttpClient) {}

  /**
   * Cikkek listázása
   * http://undefined/swagger/swagger-ui.html#!/Item/Get
   */
  get(params: GetParams): Observable<__model.ListItemsResult> {
    const queryParamBase = {
      SearchTerm: params.SearchTerm,
      OrderColumns: params.OrderColumns,
      PageIndex: params.PageIndex,
      PageSize: params.PageSize,
      MaxPageSize: params.MaxPageSize,
      SkipTotalItemCount: params.SkipTotalItemCount,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ListItemsResult>(`/api/project/Item`, {params: queryParams});
  }

  /**
   * Cikk létrehozása
   * http://undefined/swagger/swagger-ui.html#!/Item/Post
   */
  post(params: PostParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/project/Item`, bodyParams || {});
  }

  /**
   * EAN kódok listázása kiválasztásra
   * http://undefined/swagger/swagger-ui.html#!/Item/EANCodes
   */
  eanCodes(params: EanCodesParams): Observable<__model.ListEANCodesResult> {
    const queryParamBase = {
      SearchTerm: params.SearchTerm,
      OrderColumns: params.OrderColumns,
      PageIndex: params.PageIndex,
      PageSize: params.PageSize,
      MaxPageSize: params.MaxPageSize,
      SkipTotalItemCount: params.SkipTotalItemCount,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ListEANCodesResult>(`/api/project/Item/EANCodes`, {params: queryParams});
  }

  /**
   * Cikkek azonosítóinak lekérése kiválasztásra
   * http://undefined/swagger/swagger-ui.html#!/Item/SelectAll
   */
  selectAll(params: SelectAllParams): Observable<__model.SelectAllItemsResult> {
    const queryParamBase = {
      SearchTerm: params.SearchTerm,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.SelectAllItemsResult>(`/api/project/Item/SelectAll`, {params: queryParams});
  }

  /**
   * Kijelölt cikkek törlése
   * http://undefined/swagger/swagger-ui.html#!/Item/Delete
   */
  delete(params: DeleteParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/project/Item/Delete`, bodyParams || {});
  }

  /**
   * Cikk vagy készletadatok importálása
   * http://undefined/swagger/swagger-ui.html#!/Item/Upload
   */
  upload(params: UploadParams): Observable<string> {
    const pathParams = {
      fileType: params.fileType,
    };
    const formDataParams = {
      file: params.file,
    };
    return this.http.post<string>(`/api/project/Item/Upload/${pathParams.fileType}`, formDataParams);
  }

   /**
   * Összes cikk törlése
   * http://undefined/swagger/swagger-ui.html#!/Item/DeleteAll
   */
    deleteAll(params: __model.DeleteAllItemsCommand): Observable<void> {

        return this.http.post<void>(`/api/project/Item/DeleteAll`, params || {});
    }
}
