import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionProvider } from '../../core/providers/session.provider';


@Injectable()
export class LocalizationInterceptor implements HttpInterceptor {
    constructor(
        private session: SessionProvider) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const language = this.session.getCurrentLanguage();
        if (language) {
            request = request.clone({
                setHeaders: { 'Client-Language': language }
            });
        }

        return next.handle(request);
    }
}
