/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface ExportRawDataParams {
  /** format: int32 */
  ProjectId?: number;
  TranslationLanguageCode?: string;
}

export interface ExportEanGroupDataParams {
  /** format: int32 */
  ProjectId?: number;
  TranslationLanguageCode?: string;
}

export interface ExportItemNoGroupDataParams {
  /** format: int32 */
  ProjectId?: number;
  TranslationLanguageCode?: string;
}

export interface ExportEanLotGroupDataParams {
  /** format: int32 */
  ProjectId?: number;
  TranslationLanguageCode?: string;
}

export interface ExportDoubleScansDataParams {
  /** format: int32 */
  ProjectId?: number;
  TranslationLanguageCode?: string;
}

export interface ProjectLockCheckParams {
  /** format: int32 */
  ProjectId?: number;
}

export interface ProjectLockParams {
  body?: __model.LockProjectCommand;
}

@Injectable()
export class ReportsService {
  constructor(private http: HttpClient) {}

  /**
   * Nyers leltárfelvételi adatok exportálása
   * http://undefined/swagger/swagger-ui.html#!/Reports/ExportRawData
   */
  exportRawData(params: ExportRawDataParams): Observable<string> {
    const queryParamBase = {
      ProjectId: params.ProjectId,
      TranslationLanguageCode: params.TranslationLanguageCode,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.post<string>(`/api/project/Reports/ExportRawData`, {}, {params: queryParams});
  }

  /**
   * Leltárfelvételi adatok exportálása EAN kódonkénti bontásban
   * http://undefined/swagger/swagger-ui.html#!/Reports/ExportEANGroupData
   */
  exportEanGroupData(params: ExportEanGroupDataParams): Observable<string> {
    const queryParamBase = {
      ProjectId: params.ProjectId,
      TranslationLanguageCode: params.TranslationLanguageCode,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.post<string>(`/api/project/Reports/ExportEANGroupData`, {}, {params: queryParams});
  }

  /**
   * Leltárfelvételi adatok exportálása cikkszám szerinti bontásban
   * http://undefined/swagger/swagger-ui.html#!/Reports/ExportItemNoGroupData
   */
  exportItemNoGroupData(params: ExportItemNoGroupDataParams): Observable<string> {
    const queryParamBase = {
      ProjectId: params.ProjectId,
      TranslationLanguageCode: params.TranslationLanguageCode,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.post<string>(`/api/project/Reports/ExportItemNoGroupData`, {}, {params: queryParams});
  }

  /**
   * Leltárfelvételi adatok exportálása cikkszám, szavatossági idő és sarzsszám szerinti bontásban
   * http://undefined/swagger/swagger-ui.html#!/Reports/ExportEANLotGroupData
   */
  exportEanLotGroupData(params: ExportEanLotGroupDataParams): Observable<string> {
    const queryParamBase = {
      ProjectId: params.ProjectId,
      TranslationLanguageCode: params.TranslationLanguageCode,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.post<string>(`/api/project/Reports/ExportEANLotGroupData`, {}, {params: queryParams});
  }

  /**
   * Duplán felvett adatok exportálása
   * http://undefined/swagger/swagger-ui.html#!/Reports/ExportDoubleScansData
   */
  exportDoubleScansData(params: ExportDoubleScansDataParams): Observable<string> {
    const queryParamBase = {
      ProjectId: params.ProjectId,
      TranslationLanguageCode: params.TranslationLanguageCode,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.post<string>(`/api/project/Reports/ExportDoubleScansData`, {}, {params: queryParams});
  }

  /**
   * Projekt zárás feltételeinek ellenőrzése
   * http://undefined/swagger/swagger-ui.html#!/Reports/ProjectLockCheck
   */
  projectLockCheck(params: ProjectLockCheckParams): Observable<__model.ProjectStateData> {
    const queryParamBase = {
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ProjectStateData>(`/api/project/Reports/ProjectLockCheck`, {params: queryParams});
  }

  /**
   * Projekt zárása
   * http://undefined/swagger/swagger-ui.html#!/Reports/ProjectLock
   */
  projectLock(params: ProjectLockParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/project/Reports/ProjectLock`, bodyParams || {});
  }
}
