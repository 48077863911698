/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface GetParams {
  /** default: true */
  ActiveOnly?: boolean;
  IncludeBuiltIn?: boolean;
  SearchTerm?: string;
  /** format: int32 */
  ExcludeProject?: number;
  InRoles?: number[];
  TranslationLanguageCode?: string;
  OrderColumns?: string[];
  /** format: int32 */
  PageIndex?: number;
  /** format: int32 */
  PageSize?: number;
  /** format: int32 */
  MaxPageSize?: number;
  SkipTotalItemCount?: boolean;
  /** format: int32 */
  ProjectId?: number;
}

export interface PostParams {
  body?: __model.UserData;
}

export interface SetPasswordParams {
  body?: __model.SetPasswordModel;
}

export interface DeleteParams {
  body?: __model.DeleteUsersModel;
}

export interface SelectAllParams {
  /** default: true */
  ActiveOnly?: boolean;
  IncludeBuiltIn?: boolean;
  SearchTerm?: string;
  /** format: int32 */
  ExcludeProject?: number;
  InRoles?: number[];
}

export interface ExportParams {
  /** default: true */
  ActiveOnly?: boolean;
  IncludeBuiltIn?: boolean;
  SearchTerm?: string;
  /** format: int32 */
  ExcludeProject?: number;
  InRoles?: number[];
  TranslationLanguageCode?: string;
}

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  /**
   * Felhasználók listázása
   * http://undefined/swagger/swagger-ui.html#!/User/Get
   */
  get(params: GetParams): Observable<__model.ListUsersResult> {
    const queryParamBase = {
      ActiveOnly: params.ActiveOnly,
      IncludeBuiltIn: params.IncludeBuiltIn,
      SearchTerm: params.SearchTerm,
      ExcludeProject: params.ExcludeProject,
      InRoles: params.InRoles,
      TranslationLanguageCode: params.TranslationLanguageCode,
      OrderColumns: params.OrderColumns,
      PageIndex: params.PageIndex,
      PageSize: params.PageSize,
      MaxPageSize: params.MaxPageSize,
      SkipTotalItemCount: params.SkipTotalItemCount,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ListUsersResult>(`/api/global/User`, {params: queryParams});
  }

  /**
   * Felhasználó létrehozása vagy módosítása
   * http://undefined/swagger/swagger-ui.html#!/User/Post
   */
  post(params: PostParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/global/User`, bodyParams || {});
  }

  /**
   * Felhasználó jelszavának módosítása
   * http://undefined/swagger/swagger-ui.html#!/User/SetPassword
   */
  setPassword(params: SetPasswordParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/global/User/SetPassword`, bodyParams || {});
  }

  /**
   * Kijelölt felhasználók törlése
   * http://undefined/swagger/swagger-ui.html#!/User/Delete
   */
  delete(params: DeleteParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/global/User/Delete`, bodyParams || {});
  }

  /**
   * Felhasználók azonosítóinak lekérése kiválasztásra
   * http://undefined/swagger/swagger-ui.html#!/User/SelectAll
   */
  selectAll(params: SelectAllParams): Observable<__model.SelectAllUsersResult> {
    const queryParamBase = {
      ActiveOnly: params.ActiveOnly,
      IncludeBuiltIn: params.IncludeBuiltIn,
      SearchTerm: params.SearchTerm,
      ExcludeProject: params.ExcludeProject,
      InRoles: params.InRoles,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.SelectAllUsersResult>(`/api/global/User/SelectAll`, {params: queryParams});
  }

  /**
   * Felhasználók exportálása
   * http://undefined/swagger/swagger-ui.html#!/User/Export
   */
  export(params: ExportParams): Observable<string> {
    const queryParamBase = {
      ActiveOnly: params.ActiveOnly,
      IncludeBuiltIn: params.IncludeBuiltIn,
      SearchTerm: params.SearchTerm,
      ExcludeProject: params.ExcludeProject,
      InRoles: params.InRoles,
      TranslationLanguageCode: params.TranslationLanguageCode,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.post<string>(`/api/global/User/Export`, {}, {params: queryParams});
  }
}
