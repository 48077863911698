import { NgModule, APP_ID, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader, TranslateParser } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { I18NService } from 'src/api/g/controllers/I18N';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SessionProvider } from './core/providers/session.provider';
import { CustomTranslateParser } from './shared/classes/custom-translate-parser';
import { HttpTranslateLoader } from './shared/classes/http-translate-loader';
import { SharedModule } from './shared/shared.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule.forRoot(),
        RouterModule.forRoot(
            [
                { path: 'login', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
                { path: '', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) }
            ],
            {
                initialNavigation: 'disabled',
                onSameUrlNavigation: 'reload'
            }
        ),
        ModalModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (i18nService: I18NService) => new HttpTranslateLoader(i18nService),
                deps: [I18NService]
            },
            parser: {
                provide: TranslateParser,
                useFactory: () => new CustomTranslateParser()
            }
        }),
        SharedModule.forRoot()
    ],
    providers: [
        //SessionProvider, 
        { provide: APP_ID, useValue: 'ng-cli-universal' },
        { provide: LOCALE_ID, useValue: 'en-US' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
