/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

@Injectable()
export class RoleService {
  constructor(private http: HttpClient) {}

  /**
   * Szerepkörök lekérése
   * http://undefined/swagger/swagger-ui.html#!/Role/Get
   */
  role(): Observable<__model.RoleData[]> {
    return this.http.get<__model.RoleData[]>(`/api/global/Role`);
  }
}
