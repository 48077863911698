import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IModalMessage } from '../../../core/providers/notification.service';
import { take } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {
    message!: IModalMessage;

    public safeMessage: SafeHtml | undefined;

    constructor(
        public bsModalService: BsModalService,
        public bsModalRef: BsModalRef,
        private sanitizer: DomSanitizer) {
            
         }

    private getBsClass(value: string, index = 0) {
        if (index === 0) {
            switch (value) {
                case 'error': return 'danger';
                case 'warning': return 'warning';
                case 'info': return 'primary';
            }
        }

        return 'secondary';
    }

    private close(result: string) {
        if (!this.bsModalRef) {
            return;
        }

        this.bsModalService.setDismissReason(result);

        this.bsModalService.onHidden
            .pipe(take(1))
            .subscribe(_ => {
                if (this.message.onClose !== undefined) {
                    this.message.onClose(result);
                }
            });

        this.bsModalRef.hide();
    }

    ngOnInit(): void {
        this.safeMessage = this.sanitizer.bypassSecurityTrustHtml(this.message.message);
    }
}
