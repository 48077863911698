/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class ConfigService {
  constructor(private http: HttpClient) {}

  /**
   * A kliens oldalt érintő konfigurációs beállítások lekérése
   * http://undefined/swagger/swagger-ui.html#!/Config/Get
   */
  config(): Observable<object> {
    return this.http.get<object>(`/api/Config`);
  }
}
