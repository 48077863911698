/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface ChangePasswordParams {
  body?: __model.ChangePasswordModel;
}

@Injectable()
export class ProfileService {
  constructor(private http: HttpClient) {}

  /**
   * Saját felhasználói adatok lekérdezése
   * http://undefined/swagger/swagger-ui.html#!/Profile/Get
   */
  profile(): Observable<__model.UserData> {
    return this.http.get<__model.UserData>(`/api/global/Profile`);
  }

  /**
   * Saját jelszó módosítása
   * http://undefined/swagger/swagger-ui.html#!/Profile/ChangePassword
   */
  changePassword(params: ChangePasswordParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/global/Profile/ChangePassword`, bodyParams || {});
  }
  
  /**
   * Saját megjelenítési nyelv beállítása, vagy törlése
   * http://undefined/swagger/swagger-ui.html#!/Profile/SetLanguage
   */
  setLanguage(languageCode?: string): Observable<void> {
    return this.http.post<void>(`/api/global/Profile/SetLanguage?languageCode=${languageCode}`, undefined);
  }

}
