/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface PermissionsParams {
  /** format: int32 */
  UserId?: number;
  OrderColumns?: string[];
  /** format: int32 */
  PageIndex?: number;
  /** format: int32 */
  PageSize?: number;
  /** format: int32 */
  MaxPageSize?: number;
  SkipTotalItemCount?: boolean;
  /** format: int32 */
  ProjectId?: number;
}

export interface ToggleParams {
  body?: __model.TogglePermissionCommand;
}

export interface SetDefaultsParams {
  body?: __model.SetDefaultsCommand;
}

@Injectable()
export class PermissionsService {
  constructor(private http: HttpClient) {}

  /**
   * Jogosultságok listázása felhasználónként
   * http://undefined/swagger/swagger-ui.html#!/Permissions/Get
   */
  permissions(params: PermissionsParams): Observable<__model.ListPermissionsResult> {
    const queryParamBase = {
      UserId: params.UserId,
      OrderColumns: params.OrderColumns,
      PageIndex: params.PageIndex,
      PageSize: params.PageSize,
      MaxPageSize: params.MaxPageSize,
      SkipTotalItemCount: params.SkipTotalItemCount,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ListPermissionsResult>(`/api/global/Permissions`, {params: queryParams});
  }

  /**
   * Jogsultság állítása
   * http://undefined/swagger/swagger-ui.html#!/Permissions/Toggle
   */
  toggle(params: ToggleParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/global/Permissions/Toggle`, bodyParams || {});
  }

  /**
   * Jogsultság visszaállítása alapértelmezett szintre
   * http://undefined/swagger/swagger-ui.html#!/Permissions/SetDefaults
   */
  setDefaults(params: SetDefaultsParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/global/Permissions/SetDefaults`, bodyParams || {});
  }
}
