import { NgForm } from '@angular/forms';

export interface NgFormEx {
    resetFormValidation(): void;
}

(NgForm.prototype as NgForm & NgFormEx).resetFormValidation = function () {
    // https://stackoverflow.com/questions/34608361/how-to-reset-form-validation-on-submission-of-the-form-in-angular-2
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();
};
