import { TranslateDefaultParser } from '@ngx-translate/core';

export class CustomTranslateParser extends TranslateDefaultParser {
    getValue(target: any, key: string): any {
        const index = key.indexOf('|');
        let hint: string | undefined;
        if (index >= 0) {
            hint = key.substring(index + 1);
            key = key.substring(0, index);
        }

        target = key.length && (target !== null && target !== undefined) ? target[key] : undefined;

        return target || hint || key;
    }
}
