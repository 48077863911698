import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

export interface IFormEditorButtonState {
    visible: boolean;
    disabled: boolean;
    cssClass?: string;
}

@Component({
    selector: 'form-editor',
    templateUrl: './form-editor.component.html',
    styleUrls: ['./form-editor.component.scss']
})
export class FormEditorComponent implements OnInit {
    private _editing = false;

    public get editing() {
        return this._editing;
    }

    @Input()
    public set editing(value: boolean) {
        if (this._editing === value) {
            return;
        }

        this._editing = value;
        this.editing$.emit(value);
    }

    @Output('editingChange')
    public editing$ = new EventEmitter<boolean>();

    @Input()
    public editButtonState: IFormEditorButtonState = { visible: true, disabled: false };

    @Input()
    public newButtonState: IFormEditorButtonState = { visible: true, disabled: false };

    @Input()
    public saveButtonState: IFormEditorButtonState = { visible: true, disabled: false };

    @Input()
    public cancelButtonState: IFormEditorButtonState = { visible: true, disabled: false };

    @Input()
    public okButtonState: IFormEditorButtonState = { visible: false, disabled: false };


    @Input()
    public style?: {
        cssClass: string;
        header: {
            cssClass: string;
        };
        content: {
            cssClass: string;
        };
        footer: {
            cssClass: string;
            groupCssClass?: boolean;
        };
    };

    @Input()
    public canSave?: boolean;

    @Input()
    public title?: string;

    @Output('edit')
    public edit$ = new EventEmitter<boolean>();

    @Output('save')
    public save$ = new EventEmitter<void>();

    @Output('cancel')
    public cancel$ = new EventEmitter<void>();

    @ContentChild('headerTemplate', { static: false })
    public headerTemplate?: TemplateRef<any>;

    @ContentChild('contentTemplate', { static: false })
    public contentTemplate!: TemplateRef<any>;

    @ContentChild('footerTemplate', { static: false })
    public footerTemplate?: TemplateRef<any>;

    @ViewChild('editButtonTemplate', { static: true })
    public editButtonTemplate!: TemplateRef<any>;

    @ViewChild('newButtonTemplate', { static: true })
    public newButtonTemplate!: TemplateRef<any>;

    constructor() { }

    public edit(create: boolean) {
        if (this._editing) {
            return;
        }

        this.editing = true;
        this.edit$.emit(create);
    }

    public save() {
        if (!this._editing || !this.canSave) {
            return;
        }

        this.save$.emit();
    }

    public cancel() {
        if (!this._editing) {
            return;
        }

        this.editing = false;
        this.cancel$.emit();
    }

    ngOnInit() {
    }
}
