/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface GetQueueStatusParams {
  QueueName?: string;
  IncludeStats?: boolean;
}

export interface DownloadCloudDataParams {
  /** format: int32 */
  DataSet?: DataSetDownloadCloudDataParamsEnum;
  ProjectCode?: string;
  /** format: uuid */
  ProcessId?: string;
}

export type DataSetDownloadCloudDataParamsEnum =
  0 |
  1 |
  2 |
  3 |
  4 |
  5;

export interface StartCloudSyncParams {
  /** format: int32 */
  DataSet?: DataSetStartCloudSyncParamsEnum;
  ProjectCode?: string;
}

export type DataSetStartCloudSyncParamsEnum =
  0 |
  1 |
  2 |
  3 |
  4 |
  5;

@Injectable()
export class DataSyncService {
  constructor(private http: HttpClient) {}

  /**
   * A szinkronizálás leállítása
   * http://undefined/swagger/swagger-ui.html#!/DataSync/Pause
   */
  pause(): Observable<void> {
    return this.http.post<void>(`/api/global/DataSync/Pause`, {});
  }

  /**
   * A szinkronizálás folytatása
   * http://undefined/swagger/swagger-ui.html#!/DataSync/Resume
   */
  resume(): Observable<void> {
    return this.http.post<void>(`/api/global/DataSync/Resume`, {});
  }

  /**
   * Szinkronozálási várakozási sor(ok) státuszának (és opcionálisan statisztikáinak) lekérdezése
   * http://undefined/swagger/swagger-ui.html#!/DataSync/GetQueueStatus
   */
  getQueueStatus(params: GetQueueStatusParams): Observable<__model.GetDataSyncQueueStatusResult> {
    const queryParamBase = {
      QueueName: params.QueueName,
      IncludeStats: params.IncludeStats,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.GetDataSyncQueueStatusResult>(`/api/global/DataSync/GetQueueStatus`, {params: queryParams});
  }

  /**
   * Egy felhőből szinkronizált adatállomány letöltése.
   * http://undefined/swagger/swagger-ui.html#!/DataSync/DownloadCloudData
   */
  downloadCloudData(params: DownloadCloudDataParams): Observable<string | __model.DataSyncPollingModel> {
    const queryParamBase = {
      DataSet: params.DataSet,
      ProjectCode: params.ProjectCode,
      ProcessId: params.ProcessId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<string | __model.DataSyncPollingModel>(`/api/global/DataSync/DownloadCloudData`, {params: queryParams});
  }

  /**
   * Elindít egy felhő szinkronizálási folyamatot, ha nincs éppen folyamatban egy másik kizárólagos végrehajtást igénylő háttérmunka (pl. másik felhő szinkronizálás, importálás, stb.)
   * A folyamat megszakítása a válaszban kapott azonosító segítségével kezdeményezhető a M:Reflex.Controllers.ExclusiveJobController.Cancel(System.Guid) action meghívásával.
   * A háttérfolyamat a végrehajtás státuszáról push modell szerint (értesítésekkel) tájékoztatja a klienst. (TODO: SignalR infrastruktúra kiépítése)
   * http://undefined/swagger/swagger-ui.html#!/DataSync/StartCloudSync
   */
  startCloudSync(params: StartCloudSyncParams): Observable<string> {
    const queryParamBase = {
      DataSet: params.DataSet,
      ProjectCode: params.ProjectCode,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.post<string>(`/api/global/DataSync/StartCloudSync`, {}, {params: queryParams});
  }
}
