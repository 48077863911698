import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, ModuleWithProviders, ErrorHandler } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MultiSelectComponent } from "ng-multiselect-dropdown";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { GlobalErrorHandler } from "../core/providers/global-error-handler";
import { FormEditorComponent } from "./components/form-editor/form-editor.component";
import { ListViewComponent } from "./components/list-view/list-view.component";
import { MessageModalComponent } from "./components/message-modal/message-modal.component";
import { AutofocusDirective } from "./directives/autofocus.directive";
import { MustMatchDirective } from "./directives/must-match.directive";
import { JWTApiInterceptor } from "./interceptors/jwtheader.interceptor";
import { LoaderInterceptor } from "./interceptors/loader.interceptor";
import { LocalizationInterceptor } from "./interceptors/localization.interceptor";
import { QueryStringFixerInterceptor } from "./interceptors/query-string-fixer.interceptor";
import { TimezoneOffsetInterceptor } from "./interceptors/timezone-offset.interceptor";
import { FilterPipe } from "./pipes/filter.pipe";
import './extensions/array.extensions';
import './extensions/number.extensions';
import './extensions/ng-form.extensions';

@NgModule({
    declarations: [
        MessageModalComponent,
        MultiSelectComponent,
        ListViewComponent,
        FormEditorComponent,
        FilterPipe,
        AutofocusDirective,
        MustMatchDirective,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        BsDropdownModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: QueryStringFixerInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JWTApiInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LocalizationInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimezoneOffsetInterceptor, multi: true },
    ],
    exports: [
        MessageModalComponent,
        MultiSelectComponent,
        ListViewComponent,
        FormEditorComponent,
        FilterPipe,
        AutofocusDirective,
        MustMatchDirective
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                GlobalErrorHandler,
                { provide: ErrorHandler, useExisting: GlobalErrorHandler },
                FilterPipe
            ]
        };
    }
}