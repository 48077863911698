/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface GetParams {
  body?: __model.ListActiveTasksQuery;
}

export interface CancelActiveTaskParams {
  body?: __model.CancelActiveTaskCommand;
}

export interface GetActiveTasksCountQueryParams {
  projectId?: number;
}

@Injectable()
export class ActiveTasksService {
  constructor(private http: HttpClient) {}

  /**
   * Aktív feladatok listázása
   * http://undefined/swagger/swagger-ui.html#!/CreateTask/Get
   */
  get(params: GetParams): Observable<__model.ListActiveTasksResult> {
    const bodyParams = params.body;

    return this.http.post<__model.ListActiveTasksResult>(`/api/project/ActiveTasks/Get`, bodyParams || {});
  }

  /**
   * Aktív feladat semmissé tétele
   * http://undefined/swagger/swagger-ui.html#!/CreateTask/Post
   */
  cancelActiveTask(params: CancelActiveTaskParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/project/ActiveTasks/CancelActiveTask`, bodyParams || {});
  }

  getActiveTasksCount(params: GetActiveTasksCountQueryParams): Observable<number> {
    const queryParamBase = {
      ProjectId: params.projectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });
    
    return this.http.get<number>(`/api/project/ActiveTasks/GetActiveTasksCount`, {params: queryParams});
  }
}
