/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface GetParams {
  LanguageCode?: string;
  WordCodePattern?: string;
  HintPattern?: string;
  TranslationPattern?: string;
  /** format: int32 */
  Status?: StatusGetParamsEnum;
  TranslationLanguageCode?: string;
  OrderColumns?: string[];
  /** format: int32 */
  PageIndex?: number;
  /** format: int32 */
  PageSize?: number;
  /** format: int32 */
  MaxPageSize?: number;
  SkipTotalItemCount?: boolean;
  /** format: int32 */
  ProjectId?: number;
}

export type StatusGetParamsEnum =
  0 |
  1 |
  2 |
  4 |
  8;

export interface PostParams {
  body?: __model.TranslationData;
}

@Injectable()
export class DictionaryService {
  constructor(private http: HttpClient) {}

  /**
   * Fordítások listázása
   * http://undefined/swagger/swagger-ui.html#!/Dictionary/Get
   */
  get(params: GetParams): Observable<__model.ListTranslationsResult> {
    const queryParamBase = {
      LanguageCode: params.LanguageCode,
      WordCodePattern: params.WordCodePattern,
      HintPattern: params.HintPattern,
      TranslationPattern: params.TranslationPattern,
      Status: params.Status,
      TranslationLanguageCode: params.TranslationLanguageCode,
      OrderColumns: params.OrderColumns,
      PageIndex: params.PageIndex,
      PageSize: params.PageSize,
      MaxPageSize: params.MaxPageSize,
      SkipTotalItemCount: params.SkipTotalItemCount,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ListTranslationsResult>(`/api/global/Dictionary`, {params: queryParams});
  }

  /**
   * Fordítás módosítása
   * http://undefined/swagger/swagger-ui.html#!/Dictionary/Post
   */
  post(params: PostParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/global/Dictionary`, bodyParams || {});
  }
}
