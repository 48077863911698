/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface PostParams {
  body?: __model.SettingData;
}

@Injectable()
export class SettingService {
  constructor(private http: HttpClient) {}

  /**
   * Beállítások lekérése
   * http://undefined/swagger/swagger-ui.html#!/Setting/Get
   */
  get(): Observable<__model.SettingData[]> {
    return this.http.get<__model.SettingData[]>(`/api/global/Setting`);
  }

  /**
   * Beállítás módosítása
   * http://undefined/swagger/swagger-ui.html#!/Setting/Post
   */
  post(params: PostParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/global/Setting`, bodyParams || {});
  }
}
