/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface GetParams {
  ActiveOnly?: boolean;
  SearchTerm?: string;
  /** format: int32 */
  GlobalRole?: GlobalRoleGetParamsEnum;
  /** format: int32 */
  UserId?: number;
  OrderColumns?: string[];
  /** format: int32 */
  PageIndex?: number;
  /** format: int32 */
  PageSize?: number;
  /** format: int32 */
  MaxPageSize?: number;
  SkipTotalItemCount?: boolean;
  /** format: int32 */
  ProjectId?: number;
}

export interface GetCompanyUnitsParams {
  OrderColumns?: string[];
  /** format: int32 */
  PageIndex?: number;
  /** format: int32 */
  PageSize?: number;
  /** format: int32 */
  MaxPageSize?: number;
  SkipTotalItemCount?: boolean;
  /** format: int32 */
  ProjectId?: number;
}

export interface GetEventsParams {
  OrderColumns?: string[];
  /** format: int32 */
  PageIndex?: number;
  /** format: int32 */
  PageSize?: number;
  /** format: int32 */
  MaxPageSize?: number;
  SkipTotalItemCount?: boolean;
  /** format: int32 */
  ProjectId?: number;
  CompanyUnitId?: number;
  AppearanceTime?: string;
  EventCodes?: string;
}

export type GlobalRoleGetParamsEnum =
  1 |
  2 |
  3 |
  4 |
  5 |
  6 |
  7 |
  8 |
  9;

export interface PostParams {
  body?: __model.ProjectData;
}

export interface GetDeviceActivationUrlParams {
  projectCode?: string;
}

export interface GetDeviceActivationQrImageParams {
  projectCode?: string;
}

export interface UploadParams {
  /** format: binary */
  file?: string;
}

export interface SelectAllParams {
  ActiveOnly?: boolean;
  SearchTerm?: string;
  /** format: int32 */
  GlobalRole?: GlobalRoleSelectAllParamsEnum;
  /** format: int32 */
  UserId?: number;
}

export type GlobalRoleSelectAllParamsEnum =
  1 |
  2 |
  3 |
  4 |
  5 |
  6 |
  7 |
  8 |
  9;

export interface DeleteParams {
  body?: __model.DeleteProjectsModel;
}

export interface EnterParams {
  /**
   * Projekt azonosítója
   * format: int32
   */
  projectId: number;
}

export interface LeaveParams {
  /**
   * Projekt azonosítója
   * format: int32
   */
  projectId: number;
}

export interface GetProjectParams {
  /** format: int32 */
  projectId: number;
}

@Injectable()
export class ProjectService {
  constructor(private http: HttpClient) {}

  /**
   * Projektek listázása
   * http://undefined/swagger/swagger-ui.html#!/Project/Get
   */
  get(params: GetParams): Observable<__model.ListProjectsResult> {
    const queryParamBase = {
      ActiveOnly: params.ActiveOnly,
      SearchTerm: params.SearchTerm,
      GlobalRole: params.GlobalRole,
      UserId: params.UserId,
      OrderColumns: params.OrderColumns,
      PageIndex: params.PageIndex,
      PageSize: params.PageSize,
      MaxPageSize: params.MaxPageSize,
      SkipTotalItemCount: params.SkipTotalItemCount,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ListProjectsResult>(`/api/global/Project`, {params: queryParams});
  }

  getCompanyUnits(params: GetCompanyUnitsParams): Observable<__model.ListCompanyUnitsResult> {
    const queryParamBase = {
      OrderColumns: params.OrderColumns,
      PageIndex: params.PageIndex,
      PageSize: params.PageSize,
      MaxPageSize: params.MaxPageSize,
      SkipTotalItemCount: params.SkipTotalItemCount,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ListCompanyUnitsResult>(`/api/global/Project/GetCompanyUnits`, {params: queryParams});
  }

  getEvents(params: GetEventsParams): Observable<__model.ListEventsResult> {
    const queryParamBase = {
      OrderColumns: params.OrderColumns,
      PageIndex: params.PageIndex,
      PageSize: params.PageSize,
      MaxPageSize: params.MaxPageSize,
      SkipTotalItemCount: params.SkipTotalItemCount,
      ProjectId: params.ProjectId,
      CompanyUnitId: params.CompanyUnitId,
      AppearanceTime: params.AppearanceTime,
      EventCodes: params.EventCodes,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ListEventsResult>(`/api/global/Project/GetEvents`, {params: queryParams});
  }

  /**
   * Projekt létrehozása vagy módosítása
   * http://undefined/swagger/swagger-ui.html#!/Project/Post
   */
  post(params: PostParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/global/Project`, bodyParams || {});
  }

  /**
   * URL generálása PDT aktiválásához.
   * A Reflex alkalmazásnak ezt QR kódként kell a BlueBee számára elérhetővé tenni.
   * http://undefined/swagger/swagger-ui.html#!/Project/GetDeviceActivationUrl
   */
  getDeviceActivationUrl(params: GetDeviceActivationUrlParams): Observable<__model.ProjectActivationURL> {
    const queryParamBase = {
      projectCode: params.projectCode,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ProjectActivationURL>(`/api/global/Project/GetDeviceActivationUrl`, {params: queryParams});
  }

  /**
   * URL-t tartalmazó QR kód generálása PDT aktiválásához.
   * A Reflex alkalmazásnak ezt QR kódként kell a BlueBee számára elérhetővé tenni.
   * http://undefined/swagger/swagger-ui.html#!/Project/GetDeviceActivationQRImage
   */
  getDeviceActivationQrImage(params: GetDeviceActivationQrImageParams): Observable<__model.ProjectActivationURL> {
    const queryParamBase = {
      projectCode: params.projectCode,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ProjectActivationURL>(`/api/global/Project/GetDeviceActivationQRImage`, {params: queryParams});
  }

  /** http://undefined/swagger/swagger-ui.html#!/Project/Upload */
  upload(params: UploadParams): Observable<void> {
    const formDataParams = {
      file: params.file,
    };
    return this.http.post<void>(`/api/global/Project/Upload`, formDataParams);
  }

  /**
   * Projektek azonosítóinak lekérése kiválasztásra
   * http://undefined/swagger/swagger-ui.html#!/Project/SelectAll
   */
  selectAll(params: SelectAllParams): Observable<__model.SelectAllProjectsResult> {
    const queryParamBase = {
      ActiveOnly: params.ActiveOnly,
      SearchTerm: params.SearchTerm,
      GlobalRole: params.GlobalRole,
      UserId: params.UserId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.SelectAllProjectsResult>(`/api/global/Project/SelectAll`, {params: queryParams});
  }

  /**
   * Kijelölt projektek törlése
   * http://undefined/swagger/swagger-ui.html#!/Project/Delete
   */
  delete(params: DeleteParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/global/Project/Delete`, bodyParams || {});
  }

  /**
   * Projekt kiválasztása
   * http://undefined/swagger/swagger-ui.html#!/Project/Enter
   */
  enter(params: EnterParams): Observable<void> {
    const pathParams = {
      projectId: params.projectId,
    };
    return this.http.post<void>(`/api/global/Project/Enter/${pathParams.projectId}`, {});
  }

  /**
   * Kilépés a projektből
   * http://undefined/swagger/swagger-ui.html#!/Project/Leave
   */
  leave(params: LeaveParams): Observable<void> {
    const pathParams = {
      projectId: params.projectId,
    };
    return this.http.post<void>(`/api/global/Project/Leave/${pathParams.projectId}`, {});
  }

  /**
   * Projekt adatainak lekérése
   * http://undefined/swagger/swagger-ui.html#!/Project/GetProject
   */
  getProject(params: GetProjectParams): Observable<__model.ProjectData> {
    const pathParams = {
      projectId: params.projectId,
    };
    return this.http.get<__model.ProjectData>(`/api/global/Project/GetProject/${pathParams.projectId}`);
  }
}
