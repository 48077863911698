import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { delay, filter, startWith, take } from 'rxjs/operators';
import { IModalState, NotificationService } from './core/providers/notification.service';
import { SessionProvider } from './core/providers/session.provider';
import { GlobalErrorHandler } from './core/providers/global-error-handler';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    // https://stackoverflow.com/questions/36417931/angular-2-ngif-and-css-transition-animation
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [   // :enter is alias to 'void => *'
                style({ opacity: 0 }),
                animate(250, style({ opacity: 1 }))
            ]),
            transition(':leave', [   // :leave is alias to '* => void'
                animate(250, style({ opacity: 0 }))
            ])
        ])
    ]
})
export class AppComponent implements OnInit {
    private modalState$!: Observable<IModalState>;

    constructor(
        private errorHandler: GlobalErrorHandler,
        private session: SessionProvider,
        private notificationService: NotificationService,
        private router: Router,
        private translator: TranslateService) {
    }

    async ngOnInit() {
        try {
            await this.session.reloadConfig();
        } catch (error) {
            try {
                const defaultLang = 'hu-HU';
                this.translator.langs = [defaultLang];
                await this.translator.use(defaultLang).toPromise();
            } catch { }

            console.error('Loading application configuration failed.', error);
            await this.errorHandler.handleExpectedError(void 0,
                this.translator.instant('UI.Message.AppInitializationFailed|Nem sikerült betölteni az alkalmazás beállításait. ' +
                    'A szerver valószínűleg nem elérhető.Próbálja újra később, vagy értesítse az üzemeltetőt, ' +
                    'ha a hiba továbbra is fennáll!'));

            return;
        }

        this.modalState$ = this.notificationService.modalState$
            .pipe(
                startWith({ show: false, showProgress: false, progress: 0 }),
                // https://blog.angular-university.io/angular-debugging/
                delay(0));
        const bootContentEl = document.getElementById('boot-content');
        if (bootContentEl !== null) {
            this.router.events
                .pipe(
                    filter(evt => evt instanceof NavigationEnd),
                    take(1))
                .subscribe(_ => {
                    bootContentEl.remove();
                });
        }

        this.router.initialNavigation();
    }
}
