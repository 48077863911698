import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ItemImportType } from '../enums/itemimport-type';
import { ItemImportFileCheckQueryResult } from '../g/model';

@Injectable()
export class ImportService {
    constructor(private http: HttpClient) {
    }

    importProjects(file: any): Observable<void> {
        const input = new FormData();
        input.append('file', file);
        return this.http.post<void>(`/api/global/Project/Upload`, input);
    }

    importLocations(file: any): Observable<void> {
        const input = new FormData();
        input.append('file', file);
        return this.http.post<void>(`/api/project/Location/Upload`, input);
    }

    importItems(file: any, fileType: ItemImportType): Observable<void> {
        const input = new FormData();
        input.append('file', file);
        return this.http.post<void>(`/api/project/Item/Upload/${fileType}`, input);
    }

    importItemsCheck(file: any, fileType: ItemImportType): Observable<ItemImportFileCheckQueryResult> {
        const input = new FormData();
        input.append('file', file);
        return this.http.post<ItemImportFileCheckQueryResult>(`/api/project/Item/UploadCheck/${fileType}`, input);
    }
}
