import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExportParams } from '../g/controllers/User';
import { ProjectData } from '../g/defs/ProjectData';
import { SessionProvider } from '../../app/core/providers/session.provider';

@Injectable()
export class ExportService {
    constructor(private http: HttpClient, private session: SessionProvider) {
    }

    exportUsers(params: ExportParams): Promise<any> {
        return new Promise<any>((resolve) =>
            this.http.post<any>('/api/global/User/Export', params, { responseType: 'blob' as 'json' }).subscribe(result => {
                resolve(result);
            })
        );
    }
    exportStatistics(params: any): Promise<any> {
        return new Promise<any>((resolve) =>
            this.http.post<any>('/api/project/Statistics/Export', params, { responseType: 'blob' as 'json' }).subscribe(result => {
                resolve(result);
            })
        );
    }
    exportFaillist(params: any): Promise<any> {
        return new Promise<any>((resolve) =>
            this.http.post<any>('/api/project/Faillist/Export', params, { responseType: 'blob' as 'json' }).subscribe(result => {
                resolve(result);
            })
        );
    }
    exportComparison(params: any): Promise<any> {
        return new Promise<any>((resolve) =>
            this.http.post<any>('/api/project/Comparison/Export', params.body, { responseType: 'blob' as 'json' }).subscribe(result => {
                resolve(result);
            })
        );
    }
    exportReport(reportType: string): Promise<any> {
        return new Promise<any>((resolve) =>
            this.http.post<any>('/api/project/Reports/Export' + reportType, null, { responseType: 'blob' as 'json' }).subscribe(result => {
                resolve(result);
            })
        );
    }
    exportLocations(params: any): Promise<any> {
        return new Promise<any>((resolve) =>
            this.http.post<any>('/api/project/Location/Export', params.body, { responseType: 'blob' as 'json' }).subscribe(result => {
                resolve(result);
            })
        );
    }
    exportFileName(name: string, extension: string, withProject: boolean = true): string {
        const time = new Date().toISOString();
        let fileName = name;
        if (withProject === true) {
            const project = this.session.getCurrentProject();
            if (project !== null) {
                fileName += '.' + project!.projectCode + '.' + project!.location + '.' + this.untilFirstSpace(project!.partner);
            }
        }
        fileName += '.' + time + '.' + extension;
        return fileName
    }

    untilFirstSpace(name: string): string {
        if (name) {
            var firstSpace = name.indexOf(' ');
            if (firstSpace > 0) {
                return name.substring(0, firstSpace);
            } else {
                return name;
            }
        }

        return name;
    }

}
