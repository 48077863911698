// a  Reflex.Service.Contract.ServiceErrorCode C# enum tükörképe, manuálisan kell szinkronban tartani!
export enum ApiError400 {
    Unknown = 0,
    ParamNotSpecified = 1,
    ParamNotValid = 2,
    EntityNotFound = 3,
    EntityNotUnique = 4,
    PermissionDenied = 5,
    ForeignKeyNotNull = 6,
    ConcurrencyConflict = 1000,
    ExclusiveJobCannotStarted = 1500,
    DataSyncDown = 2000,
    DataSyncInitialDbNotAvailable = 2001,
    DataSyncRequestTooManyChanges = 2100,
    ImportTaskGeneralError = 3001,
    ImportTaskCSVFormatError = 3002,
    TaskAlreadyTaken = 4001,
    ProjectsNotLocked = 5001,
    UserMustBeParticipantToLockProject = 5002,
    ProjectImportFileIsInvalid = 5003,
    LocationExists = 6001,
    LocationsWithScanCannotBeDeleted = 6002,
    LocationNotExists = 6003,
    LocationsInVerifyTaskCannotBeDeleted = 6004,
    StockDataNeededForComparison = 7001,
    ParticipantsDeviceIdNotNull = 8001
}

export enum ApiError400DefaultTranslation {
    ConcurrencyConflict = 'Konkurrens módosítás történt: ' +
        'egy másik felhasználó időközben ugyanezeket az adatokat módosította. Emiatt az Ön módosításai visszavonásra kerültek.',
}
