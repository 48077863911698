import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { ApiModule } from '../../api/api.module';
import { GlobalErrorHandler } from './providers/global-error-handler';
import { NotificationService } from './providers/notification.service';
import { SessionProvider } from './providers/session.provider';


@NgModule({

    imports: [
        CommonModule,
        HttpClientModule,
        ApiModule
    ],
})
export class CoreModule {
    // https://angular.io/guide/ngmodule-faq#what-is-the-forroot-method
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                GlobalErrorHandler,
                { provide: ErrorHandler, useExisting: GlobalErrorHandler },
                NotificationService,
                SessionProvider
            ]
        };
    }
}
