/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface GetParams {
  SearchTerm?: string;
  LHA?: string[];
  OrderColumns?: string[];
  /** format: int32 */
  PageIndex?: number;
  /** format: int32 */
  PageSize?: number;
  /** format: int32 */
  MaxPageSize?: number;
  SkipTotalItemCount?: boolean;
  /** format: int32 */
  ProjectId?: number;
}

export interface PostParams {
  body?: __model.LocationData;
}

export interface SelectAllParams {
  SearchTerm?: string;
  LHA?: string[];
  /** format: int32 */
  ProjectId?: number;
}

export interface DeleteParams {
  body?: __model.DeleteLocationsModel;
}

export interface UploadParams {
  /** format: binary */
  file?: string;
}

export interface GetLocationByIdParams {
  /** format: int32 */
  Id?: number;
}

export interface ExportParams {
  body?: __model.ExportLocationsQuery;
}

@Injectable()
export class LocationService {
  constructor(private http: HttpClient) {}

  /**
   * Tárhelyek listázása
   * http://undefined/swagger/swagger-ui.html#!/Location/Get
   */
  get(params: GetParams): Observable<__model.ListLocationsResult> {
    const queryParamBase = {
      SearchTerm: params.SearchTerm,
      LHA: params.LHA,
      OrderColumns: params.OrderColumns,
      PageIndex: params.PageIndex,
      PageSize: params.PageSize,
      MaxPageSize: params.MaxPageSize,
      SkipTotalItemCount: params.SkipTotalItemCount,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ListLocationsResult>(`/api/project/Location`, {params: queryParams});
  }

  /**
   * Leltárhely létrehozása vagy módosítása
   * http://undefined/swagger/swagger-ui.html#!/Location/Post
   */
  post(params: PostParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/project/Location`, bodyParams || {});
  }

  /**
   * Leltárhelyek azonosítóinak lekérése kiválasztásra
   * http://undefined/swagger/swagger-ui.html#!/Location/SelectAll
   */
  selectAll(params: SelectAllParams): Observable<__model.SelectAllLocationsResult> {
    const queryParamBase = {
      SearchTerm: params.SearchTerm,
      LHA: params.LHA,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.SelectAllLocationsResult>(`/api/project/Location/SelectAll`, {params: queryParams});
  }

  /**
   * Kijelölt leltárhelyek törlése
   * http://undefined/swagger/swagger-ui.html#!/Location/Delete
   */
  delete(params: DeleteParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/project/Location/Delete`, bodyParams || {});
  }

  /** http://undefined/swagger/swagger-ui.html#!/Location/Upload */
  upload(params: UploadParams): Observable<void> {
    const formDataParams = {
      file: params.file,
    };
    return this.http.post<void>(`/api/project/Location/Upload`, formDataParams);
  }

  /**
   * Egy tárhely adatainak letöltése Id alapján
   * http://undefined/swagger/swagger-ui.html#!/Location/GetLocationById
   */
  getLocationById(params: GetLocationByIdParams): Observable<__model.LocationData> {
    const queryParamBase = {
      Id: params.Id,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.LocationData>(`/api/project/Location/GetLocationById`, {params: queryParams});
  }

  /**
   * Leltárhely adatok exportálása
   * http://undefined/swagger/swagger-ui.html#!/Location/Export
   */
  export(params: ExportParams): Observable<string> {
    const bodyParams = params.body;

    return this.http.post<string>(`/api/project/Location/Export`, bodyParams || {});
  }
}
