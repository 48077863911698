const _2pow31 = 2 ** 31;
const _2pow31minus1 = _2pow31 - 1;
export { }
declare global {
    export interface Number {
        hasAllFlags(value: number): boolean;
        hasAnyFlags(value: number): boolean;
        getFlags(): number[];
    }
}

// bitműveletek esetén a JS fixen lekonvertálja a számot 32 bitre (bármiféle figyelmeztetés nélkül...),
// ezért nagyobb számoknál egy kicsit ügyeskedni kell
function bitwiseAnd64(v1: number, v2: number) {
    // 32 bites előjeles egészeknél jó az alap működés
    if (v1 < _2pow31 && v2 < _2pow31) {
        return v1 & v2;
    }

    // egyébként 52 bitig vagyunk jók
    if (v1 > Number.MAX_SAFE_INTEGER || v2 > Number.MAX_SAFE_INTEGER) {
        throw new Error('Operand cannot be represented safely.');
    }

    // https://stackoverflow.com/questions/2983206/bitwise-and-in-javascript-with-a-64-bit-integer
    const hi1 = ~~(v1 / _2pow31);
    const hi2 = ~~(v2 / _2pow31);
    const low1 = v1 & _2pow31minus1;
    const low2 = v2 & _2pow31minus1;
    return (hi1 & hi2) * _2pow31 + (low1 & low2);
}

Number.prototype.hasAllFlags = function (value: number) {
    return bitwiseAnd64(this.valueOf(), value) === value;
};

Object.defineProperty(Number.prototype, 'hasAllFlags', { enumerable: false });

Number.prototype.hasAnyFlags = function (value: number) {
    return bitwiseAnd64(this.valueOf(), value) !== 0;
};

Object.defineProperty(Number.prototype, 'hasAnyFlags', { enumerable: false });

Number.prototype.getFlags = function () {
    const result: number[] = [];
    for (let value = 1; value <= Number.MAX_SAFE_INTEGER; value *= 2) {
        if (this.hasAnyFlags(value)) {
            result.push(value);
        }
    }
    return result;
};

Object.defineProperty(Number.prototype, 'getFlags', { enumerable: false });
