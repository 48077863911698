/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface LoginParams {
  body?: __model.LoginRequestModel;
}

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {}

  /**
   * Bejelentkezés felhasználói név és jelszó segítségével.
   * http://undefined/swagger/swagger-ui.html#!/Auth/Login
   */
  login(params: LoginParams): Observable<__model.LoginResponseModel> {
    const bodyParams = params.body;

    return this.http.post<__model.LoginResponseModel>(`/api/Auth`, bodyParams || {});
  }

  /**
   * A bejelentkezett felhasználó adatainak lekérése, vagy a munkamenet ellenőrzése.
   * http://undefined/swagger/swagger-ui.html#!/Auth/Check
   */
  check(): Observable<__model.GetUserInfoResult> {
    return this.http.get<__model.GetUserInfoResult>(`/api/Auth`);
  }
}
