/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface GetParams {
  SearchTerm?: string;
  TranslationLanguageCode?: string;
  OrderColumns?: string[];
  /** format: int32 */
  PageIndex?: number;
  /** format: int32 */
  PageSize?: number;
  /** format: int32 */
  MaxPageSize?: number;
  SkipTotalItemCount?: boolean;
  /** format: int32 */
  ProjectId?: number;
}

export interface PutParams {
  body?: __model.AddParticipantCommand;
}

export interface PostParams {
  body?: __model.EditParticipantCommand;
}

export interface RemoveParams {
  body?: __model.RemoveParticipantCommand;
}

export interface GetWorklogParams {
  SearchTerm?: string;
  OrderColumns?: string[];
  /** format: int32 */
  PageIndex?: number;
  /** format: int32 */
  PageSize?: number;
  /** format: int32 */
  MaxPageSize?: number;
  SkipTotalItemCount?: boolean;
  /** format: int32 */
  ProjectId?: number;
}

export interface RemoveWorklogEventParams {
  body?: __model.RemoveWorklogEventCommand;
}

@Injectable()
export class ParticipantService {
  constructor(private http: HttpClient) {}

  /**
   * Projekthez rendelt felhasználók listázása
   * http://undefined/swagger/swagger-ui.html#!/Participant/Get
   */
  get(params: GetParams): Observable<__model.ListParticipantsResult> {
    const queryParamBase = {
      SearchTerm: params.SearchTerm,
      TranslationLanguageCode: params.TranslationLanguageCode,
      OrderColumns: params.OrderColumns,
      PageIndex: params.PageIndex,
      PageSize: params.PageSize,
      MaxPageSize: params.MaxPageSize,
      SkipTotalItemCount: params.SkipTotalItemCount,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ListParticipantsResult>(`/api/project/Participant`, {params: queryParams});
  }

  /**
   * Személy hozzárendelése
   * http://undefined/swagger/swagger-ui.html#!/Participant/Put
   */
  put(params: PutParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.put<void>(`/api/project/Participant`, bodyParams || {});
  }

  /**
   * Személy hozzárendelésének módosítása
   * http://undefined/swagger/swagger-ui.html#!/Participant/Post
   */
  post(params: PostParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/project/Participant`, bodyParams || {});
  }

  /**
   * Személy hozzáférésének visszavonása
   * http://undefined/swagger/swagger-ui.html#!/Participant/Remove
   */
  remove(params: RemoveParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/project/Participant/Remove`, bodyParams || {});
  }

  /**
   * Munkanapló-események listázása
   * http://undefined/swagger/swagger-ui.html#!/Participant/GetWorklog
   */
  getWorklog(params: GetWorklogParams): Observable<__model.ListWorklogResult> {
    const queryParamBase = {
      SearchTerm: params.SearchTerm,
      OrderColumns: params.OrderColumns,
      PageIndex: params.PageIndex,
      PageSize: params.PageSize,
      MaxPageSize: params.MaxPageSize,
      SkipTotalItemCount: params.SkipTotalItemCount,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ListWorklogResult>(`/api/project/Participant/GetWorklog`, {params: queryParams});
  }

  /**
   * Személy munkanapló bejegyzésének visszavonása
   * http://undefined/swagger/swagger-ui.html#!/Participant/RemoveWorklogEvent
   */
  removeWorklogEvent(params: RemoveWorklogEventParams): Observable<void> {
    const bodyParams = params.body;

    return this.http.post<void>(`/api/project/Participant/RemoveWorklogEvent`, bodyParams || {});
  }
}
