/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface StatusParams {
  Area?: string;
  Zone?: string;
  LHA?: string[];
  ScanUser?: string;
  CheckUser?: string;
  /** format: int32 */
  LocationState?: LocationStateStatusParamsEnum;
  TranslationLanguageCode?: string;
  OrderColumns?: string[];
  /** format: int32 */
  PageIndex?: number;
  /** format: int32 */
  PageSize?: number;
  /** format: int32 */
  MaxPageSize?: number;
  SkipTotalItemCount?: boolean;
  /** format: int32 */
  ProjectId?: number;
}

export type LocationStateStatusParamsEnum =
  0 |
  1 |
  2 |
  4 |
  8 |
  16 |
  32 |
  64 |
  128 |
  256;

export interface SelectAllParams {
  Area?: string;
  Zone?: string;
  LHA?: string[];
  ScanUser?: string;
  CheckUser?: string;
  /** format: int32 */
  LocationState?: LocationStateSelectAllParamsEnum;
  /** format: int32 */
  ProjectId?: number;
  TranslationLanguageCode?: string;
}

export type LocationStateSelectAllParamsEnum =
  0 |
  1 |
  2 |
  4 |
  8 |
  16 |
  32 |
  64 |
  128 |
  256;

@Injectable()
export class StatusService {
  constructor(private http: HttpClient) {}

  /**
   * Státusz adatok listázása
   * http://undefined/swagger/swagger-ui.html#!/Status/Get
   */
  status(params: StatusParams): Observable<__model.ListStatusResult> {
    const queryParamBase = {
      Area: params.Area,
      Zone: params.Zone,
      LHA: params.LHA,
      ScanUser: params.ScanUser,
      CheckUser: params.CheckUser,
      LocationState: params.LocationState,
      TranslationLanguageCode: params.TranslationLanguageCode,
      OrderColumns: params.OrderColumns,
      PageIndex: params.PageIndex,
      PageSize: params.PageSize,
      MaxPageSize: params.MaxPageSize,
      SkipTotalItemCount: params.SkipTotalItemCount,
      ProjectId: params.ProjectId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ListStatusResult>(`/api/project/Status`, {params: queryParams});
  }

  /**
   * Leltárhelyek azonosítóinak lekérése kiválasztásra
   * http://undefined/swagger/swagger-ui.html#!/Status/SelectAll
   */
  selectAll(params: SelectAllParams): Observable<__model.SelectAllStatusResult> {
    const queryParamBase = {
      Area: params.Area,
      Zone: params.Zone,
      LHA: params.LHA,
      ScanUser: params.ScanUser,
      CheckUser: params.CheckUser,
      LocationState: params.LocationState,
      ProjectId: params.ProjectId,
      TranslationLanguageCode: params.TranslationLanguageCode,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.SelectAllStatusResult>(`/api/project/Status/SelectAll`, {params: queryParams});
  }
}
