/* tslint:disable:max-line-length */
/**
 * Reflex API
 * v1
 * undefined
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

export interface GetTranslationsParams {
  langCode?: string;
}

@Injectable()
export class I18NService {
  constructor(private http: HttpClient) {}

  /**
   * Támogatott nyelvek lekérése
   * http://undefined/swagger/swagger-ui.html#!/I18n/GetLanguages
   */
  getLanguages(): Observable<object> {
    return this.http.get<object>(`/api/I18n/GetLanguages`);
  }

  /**
   * Fordítások lekérése
   * http://undefined/swagger/swagger-ui.html#!/I18n/GetTranslations
   */
  getTranslations(): Observable<object> {
    const queryParamBase = {
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<object>(`/api/I18n/GetTranslations`, {params: queryParams});
  }
}
